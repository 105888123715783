import React from "react";
import Styles from "./Popup.module.css";

import cancel from "../../Images/cancel.png"

const Popup = ({close}) => {

  return (
    <div className={Styles.Popup}>
      <div className={Styles.info}>
          <img src={cancel} alt="" onClick={close}/>
        <section>
          <h1>1. OPEN METAMASK</h1>
          <p>
            If you are using regular browser, by clicking Connect Wallet button
            your metamask app will open. <br /> <span>IMPORTANT:</span> Make sure you are on right
            url <span>https://www.standagainstwar.com</span>
          </p>
        </section>
        <section>
          <h1>2. CONNECT WALLET</h1>
          <p>
            Our system ask you to connect wallet automatically when page is
            loaded or you can simply click Connect Wallet button.
          </p>
        </section>
        <section>
          <h1>3. MINT</h1>
          <p>
            If you are using Metamask or Trust Wallet app and your wallet is
            connected, you should be able to click Mint button and proceed to
            Mint our NFT!
          </p>
        </section>
        <p>
          Here is our detailed guide on Medium: <a href="#">How to mint Peace Nightingales</a>
        </p>
      </div>
    </div>
  );
};

export default Popup;
