import React, { useState, useEffect } from "react";
import Styles from "./Landing.module.css";

import locked from "../../Images/locked.png";
import nightingales from "../../Images/nightingales.png";
import logo from "../../Images/landingLogo.png";
import Text from "../../Images/PNText.svg";
import bird from "../../Images/bird-redbg.png";
import Logo from "../../assets/image/Logo.png";
import top from "../../Images/viralTopBg.png";
import bottom from "../../Images/viralBottomBg.png";
import arrow1 from "../../Images/viralArrow1.svg";
import arrow2 from "../../Images/viralArrow2.svg";
import roadmap from "../../Images/roadmapBG.png";
import roadmapmobile from "../../Images/roadMapMobile.png";

import Mailchimp from "../../components/Mailchimp/Mailchimp";
import Spotlight from "../../components/Spotlight/Spotlight";
import Team from "./../../components/Team/Team";
import LandingPagePopUp from "../../components/Mailchimp/LandingPagePopUp";

import menu from "../../Images/hamburgerMenu.png";
import global from "./../../Images/global.png";
import hands from "./../../Images/care-clear.png";
import happy from "./../../Images/happy.png";
import wck from "./../../Images/wck.png";
import hope from "./../../Images/hope.png";
import play from "./../../Images/play-clear.png";
import certik from "./../../Images/certik.png";
import rarity from "./../../Images/rarity.png";

import AOS from "aos";
import "aos/dist/aos.css";
import Authenticated from "../../components/Authenticated/Authenticated";


const Landing = () => {
  const faq = [
    {
      question: "What is Stand Against War?",
      answer:
        "Stand Against War is a social collective that brings about a digital art revolution to support social causes. In our first project, we have created a collection of 10,000 unique NFTs called the Peace Nightingales that will help the people in Ukraine alongside our second collection that will be dropped in Phase 2.",
    },
    {
      question: "Will Stand Against War launch more collections?",
      answer:
        "Stand Against War is an ongoing initiative that will tackle all the social causes that have infecting the roots of the world. The team will launch multiple collections to support each initiative, and will also allow the community members to ideate on forthcoming projects.",
    },
    {
      question: "How can I work for Stand Against War?",
      answer:
        "Being a social collective, we are always looking out to get more and more volunteers on board. If you think that you can help us in any capacity ranging from artwork creation to digital marketing and promotion, you can reach out to us at help@standagainstwar.com",
    },
    {
      question: "How are the donations getting transferred to the NGOs?",
      answer:
        "The functionality of the entire donation segment runs on the basis of a smart contract that has been audited multiple times to evade errors. 90% of the total sales proceeds plus 90% of the totally royalties earned will be directly transferred to our partner NGOs. The sample receipts and the proof of collaboration is up on our social media channels!",
    },
    {
      question:
        "How are these donations any different from the centralized relief funds?",
      answer:
        "The total donation generated through this project is automatically transferred to the partner NGOs in their respective wallets. Since everything is governed using a smart contract, nobody including the developers can alter its machinery, and therefore you can be sure of your donation in addition to wearing your NFT as a badge of honor.",
    },
  ];

  const [menuModal, setMenuModal] = useState(false);
  const [more, setMore] = useState(false);

  const navigate = () => {
    setMenuModal(false);
    (window.open ("https://discord.gg/rRk28c49wd", "_blank"));
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className={Styles.Landing}>
      <div className={Styles.hero} data-aos="fade-in">
        <nav>
        <div className={Styles.imglogo} onClick={() => (window.location.href = "/")}>
          <img src={Logo} alt="" />
          <h2>
            Stand<span>Against</span>War
          </h2>
        </div>
          <div className={Styles.socialHeaderIcons}>
            {/* <a href="https://t.me/standagainstwarchannel" target="_blank">
              <i className="fab fa-telegram" />
            </a> */}
            <a href="https://twitter.com/againstwar_nfts" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://discord.gg/rRk28c49wd" target="_blank">
              <i className="fab fa-discord" />
            </a>
            <a
              href="https://www.facebook.com/Stand-Against-War-100332872629564"
              target="_blank"
            >
              <i className="fab fa-facebook" />
            </a>
            <a
              href="https://www.instagram.com/standagainst.war/"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
                    <ul>
            <li>
              <a href="./peacenightingales">Product</a>
            </li>
            <li>
              <a href="./#FAQ">FAQ</a>
            </li>
            <li>
              <a href="./#Roadmap">Roadmap</a>
            </li>
          </ul>
          <button onClick={() => (window.open ("https://discord.gg/rRk28c49wd", "_blank"))}>
            Join Community
          </button>

          <div className={Styles.menu}>
            <img src={menu} onClick={() => setMenuModal(!menuModal)} alt="" />
          </div>
          {menuModal && (
            <div className={Styles.menuModal}>
              <div className={Styles.mobileLinks}>
                <nav>
                  <ul onClick={() => setMenuModal(false)}>
                    <li>
                      <a href="./peacenightingales">Product</a>
                    </li>
                    <li>
                      <a href="/#FAQ">FAQ</a>
                    </li>
                    <li>
                      <a href="/#Roadmap">Roadmap</a>
                    </li>
                  </ul>
                  <button onClick={() => navigate()}>Join Community</button>
                </nav>
              </div>
            </div>
          )}
        </nav>
        <div className={Styles.text}>
          <h2>
            <b>A Better World is Possible </b> - Stand and Fight for a Better World!
          </h2>
          <p>
          Stand Against War is an NFT collection with a very simple vision: to build a community of peace-makers in the metaverse who can, collectively, affect real change in the real world.
          </p>

          <div className={Styles.whiteBorder}>
            <p>
              <p>Official NGO Partners</p>
              <span>
                <img src={happy} alt="" />
                <img src={play} alt="" />
                <img src={wck} alt="" />
                <img src={hope} alt="" />
                <img src={global} alt="" />
                <img src={hands} alt="" />
              </span>
            </p>
          </div>

          <button
            onClick={() => (window.location.href = "/#Whitelist")}
          >
            Get Whitelisted
          </button>
        </div>
        <div className={Styles.logo}>
          <div className={Styles.rainbowCard}>
            <img src={bird} alt="" />
            <p>Stand With Ukraine</p>
            <button onClick={() => (window.location.href = "/peacenightingales")}>
              Adopt A Nightingale
            </button>
          </div>
        </div>
      </div>
      <Authenticated text='We are authenticated by' logo={certik}/>
      <div className={Styles.viral}>
        {/* <img src={top} alt="" /> */}
        {/* <div className={Styles.viralMain} data-aos="zoom-in-left">
          <div className={Styles.text}>
            <img src={Text} className={Styles.textImg} alt="" />
            <p>going to be viral soon!</p>
            <img src={arrow1} className={Styles.arrow1} alt="" />
            <p>Dont be late...</p>
            <h3>
              start minting now!
              <img src={arrow2} className={Styles.arrow2} alt="" />
            </h3>
          </div>
          <div className={Styles.rainbowCard}>
            <img src={bird} alt="" />
            <p>Stand With Ukraine</p>
            <button onClick={() => (window.location.href = "/peacenightingales")}>
              Adopt A Nightingale
            </button>
          </div>
        </div>
        <hr /> */}
        <div className={Styles.viralBottom} data-aos="fade-right">
          <div className={Styles.nightingales}>
            <h2>10,000 Peace Nightingales</h2>
            <p>
              The Peace Nightingales is a NFT collection of 10,000 unique tokens
              that are ready for minting. After purchasing any of the
              nightingales, you get to wear your badge of honour with pride and
              integrity!
            </p>
          </div>
          <img src={nightingales} alt="" />
        </div>
        <div className={Styles.viralDiscord} data-aos="zoom-out">
          <p>Join The Peace Discord</p>
          <a href="https://discord.gg/rRk28c49wd" target="_blank">
            <i className="fab fa-discord" />
          </a>
        </div>
        {/* <img src={bottom} alt="" /> */}
      </div>
      <div className={Styles.manifesto}>
        <div className={Styles.manifestoText}>
          <h1>Word from our Founders</h1>
          <h5>The team behined this cause</h5>
          <p>
            The world is crumbling under one crisis after crisis. Right when we
            thought our battle with a pandemic was over, we were all engulfed
            with this overwhelming fear of a WWIII breaking out. And rightly so,
            we are a generation of aware and woke humans who recognise the vices
            of war. A war is a disaster for the environment, it’s a mockery of
            the divine gift of life-force, it’s a waste of precious resources
            and finances that can definitely be put to better use... There is
            nothing pretty about war. Zit. Nothing. Nada! <br /> <br />
            {more && (
              <section>
                {" "}
                And yet Ukraine, Afghanistan, Iraq, Syria, Yemen, Mexico,
                Somalia, Libya... These many countries and peoples are currently
                torn by war.
                <br /> <br />
                So, we decided to do something about it. If truth be told, this
                project decided to find us and now we are on a mission to find
                like-minded peace-loving humans like you.
                <br /> <br />
                We plan to scale this project and develop into a DAO-enabled NFT
                marketplace in the 2nd phase, so our community can collectively
                build a better world, in the metaverse and in the real world!
                <br /> <br />
                Peace Nightingales for Ukraine is just the beginning. Together
                let us bring back balance, brotherhood and peace to this world.
                Let us lend helping hands to hopeful hearts and build a world
                for 2.0 version of humanity. Let us{" "}
                <span>Stand Against War!</span>”
                <br /> <br />
                Check out our projected roadmap
                <br />
                Peace Nightingales waiting to send relief to Ukraine!{" "}
              </section>
            )}
            <span onClick={() => setMore(!more)}>
              {!more ? <p>...read more</p> : <p>...read less</p>}
            </span>
          </p>
        </div>
      </div>
      <div id="Roadmap" className={Styles.roadmap}>
        <img
          src={roadmap}
          className={Styles.desktop}
          alt=""
          data-aos="fade-left"
        />
        <img
          src={roadmapmobile}
          className={Styles.mobile}
          alt=""
          data-aos="fade-left"
        />
        <h2>
          Roadmap <br /> Teaser
        </h2>
        <section data-aos="fade-right">
          <div className={Styles.text}>
            <h2>
              Phase 1: <br />
              Pilot
            </h2>
            <p>First Drop + Community building</p>
          </div>
          <span className={Styles.icon}>
            <img src={bird} alt="" />
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
        <section data-aos="fade-right">
          <div className={Styles.text}>
            <h2>
              Phase 2: <br />
              The Stand Against War Community
            </h2>
            <p>
              Second Drop, Curation of Online/Virtual events for the community,
              Collaborations and Discussions on the next steps
            </p>
          </div>
          <span className={Styles.icon}>
            <img src={locked} alt="" />
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
        <section data-aos="fade-right">
          <div className={Styles.text}>
            <h2>
              Phase 3: <br />
              Entry into the Metaverse
            </h2>
            <p>Play to Earn Games, Exclusive on Roadmap</p>
          </div>
          <span className={Styles.icon}>
            <img src={locked} alt="" />
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
      </div>
      <Spotlight />
      <LandingPagePopUp />
      <Team />
      <div className={Styles.Email} id="Whitelist">
      <Mailchimp />
      </div>
      <div id="FAQ" className={Styles.FAQ}>
        <section
          className={Styles.main}
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1>Frequently Asked Questions</h1>
          <div className={Styles.FAQtab}>
            {faq.map(({ question, answer }, k) => (
              <details key={k}>
                <summary>{question}</summary>
                <p>{answer}</p>
              </details>
            ))}
          </div>
        </section>
      </div>
      <Authenticated text='Check rarity on' logo={rarity} link={"https://raritysniper.com"}/>
      <footer>
        <div className={Styles.container}>
          <div className={Styles.navLinks}>
            <ul>
              {/* <li>
              <Link to="./">Connect</Link>
            </li> */}
              <li>
                <a href="mailto:help@standagainstwar.com">Contact</a>
              </li>
              {/* <li>
              <Link to="./">Opensea</Link>
            </li> */}
            </ul>
          </div>
          <p>We can't reply to all emails but all good vibes are appreciated</p>
          <div className={Styles.socialIcons}>
            {/* <a href="https://t.me/standagainstwarchannel" target="_blank">
              <i className="fab fa-telegram" />
            </a> */}
            <a href="https://twitter.com/againstwar_nfts" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://discord.gg/rRk28c49wd" target="_blank">
              <i className="fab fa-discord" />
            </a>
            <a
              href="https://www.facebook.com/Stand-Against-War-100332872629564"
              target="_blank"
            >
              <i className="fab fa-facebook" />
            </a>
            <a
              href="https://www.instagram.com/standagainst.war/"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
          <button
            onClick={() => (window.location.href = "/#Whitelist")}
          >
            Get Whitelisted
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
