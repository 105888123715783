import React from "react";
import Tilt from 'react-parallax-tilt';

import bird from "./../../Images/bird.png";
import Evan from "./../../Images/Avatar-Evan.png";
import Akhil from "./../../Images/Avatar-Akhil.png";
import Ayush from "./../../Images/Avatar-Ayush.png";
import Karan from "./../../Images/Avatar-Karan.png";
import Raunak from "./../../Images/Avatar-Raunak.png";
import Emmanuel from "./../../Images/Avatar-Emmanuel.png";
import jacqueline from "./../../Images/Avatar-Jacqueline.png";
import Nabeel from "./../../Images/Avatar-Nabeel.png";
import Naveen from "./../../Images/Avatar-Naveen.png";
import Sumit from "./../../Images/Avatar-Mukund.png";
import forbes from "./../../Images/forbes.svg";
import NFTEvan from "./../../Images/NFT-Evan.png";
import NFTAkhil from "./../../Images/NFT-Akhil.jpeg";

import Styles from "./Team.module.css"

const Team = () => {
    
  const team = [
    {
      Name: "EVAN LUTHRA",
      Position: "Chief Advisor",
      Socials: {
        Twitter: "https://twitter.com/evanluthra?s=21&t=L6fG29T06TPLOOmcDgf12g",
        Discord: null,
        Website: "https://evanluthra.com/",
        Instagram: "https://instagram.com/evanluthra?igshid=YmMyMTA2M2Y=",
      },
      Avatar: Evan,
      Bio: "is a well renowned entrepreneur, investor and influential speaker in the blockchain and cryptocurrency space.",
      Forbes: true,
      NFT: NFTEvan,
      NFTLink: "https://opensea.io/assets/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/3213/",
    },
    {
      Name: "AKHIL BVS",
      Position: "Advisor",
      Socials: {
        Twitter: "https://twitter.com/akhil_bvs?s=21&t=L6fG29T06TPLOOmcDgf12g",
        Discord: null,
        Website: null,
        Instagram: "https://instagram.com/akhil_bvs?igshid=YmMyMTA2M2Y=",
      },
      Avatar: Akhil,
      Bio: "is a NFT expert who is currently building the Asset Money platform. He is also an active member at SuperTeam DAO.",
      NFT: NFTAkhil,
      NFTLink: "https://opensea.io/assets/0xc92ceddfb8dd984a89fb494c376f9a48b999aafc/5257",
    },
    {
      Name: "AYUSH KHETAN",
      Position: "CEO",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Ayush,
    },
    {
      Name: "KARAN SHARMA",
      Position: "COO",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Karan,
    },
    {
      Name: "NAVEEN AGARWAL",
      Position: "CTO/ Lead Dev",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Naveen,
    },
    {
      Name: "RAUNAK AGARWAL",
      Position: "CMO",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Raunak,
    },
    {
      Name: "JACQUELINE",
      Position: "Chief Admin",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: jacqueline,
    },
    {
      Name: "EMMANUEL CHARLES",
      Position: "Front End Dev",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Emmanuel,
    },
    {
      Name: "NABEEL SALEEM",
      Position: "UI/UX",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Nabeel,
    },
    {
      Name: "SUMIT SHARMA",
      Position: "Research Associate",
      Socials: {
        Twitter: null,
        Discord: null,
        Website: null,
        Instagram: null,
      },
      Avatar: Sumit,
    },
  ];

  return (
    <div>
      {" "}
      <div className={Styles.team}>
        <h2>Our Team</h2>
        <p>We have a dedicated and outstanding team</p>
        <section className={Styles.teamCards}>
          {team.map(({ Name, Position, Socials, Avatar, Forbes, Bio, NFT, NFTLink }, k) => (
            <Tilt
              glareEnable={true}
              glareMaxOpacity={0.5}
              glareColor="#faf2ec"
              glarePosition="bottom"
              className={Styles.card}
              key={k}
              data-aos="flip-left"
              duration="1000ms"
            >
              <div>
                <h2>{Name}</h2>
                <p>{Position}</p>
                <div>
                  {Socials.Twitter && (
                    <a href={Socials.Twitter} target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  )}
                  {Socials.Discord && (
                    <a href={Socials.Discord} target="_blank">
                      <i className="fab fa-discord" />
                    </a>
                  )}
                  {Socials.Instagram && (
                    <a href={Socials.Instagram} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  )}
                  {Socials.Website && (
                    <a href={Socials.Website} target="_blank">
                      <i className="far fa-globe" />
                    </a>
                  )}
                  {NFT && (
                    <a>
                      <img src={bird} alt="" className={Styles.bird}/>
                      </a>
                  )}
                </div>
                {NFT ? <img src={NFT} alt="" className={Styles.NFT} onClick={() => window.open(`${NFTLink}`, "_blank")}/> : <img src={Avatar} alt="" />}
                {Bio && (
                  <section className={Styles.bio}>
                    {Forbes && <img src={forbes} alt="" />}
                    <p>
                      <b>{Name}</b> {Bio}
                    </p>
                  </section>
                )}
              </div>
            </Tilt>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Team;
