import React, { useState, useEffect } from 'react';
import nightingale from "./../../Images/popupimg.png";
import cancel from "./../../Images/cancel.png";

import Styles from "./LandingPageForm.module.css";

const LandingPageForm = ({ status, message, onValidated, setPopUp, submit }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // // const [birthDay, setBirthDay] = useState('18/18/18');
    const [validationMessage, setValidationMessage] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        // {!lastName && setValidationMessage("Please Enter Your Last Name")}
        {!firstName && setValidationMessage("Please Enter Your Name")}
        {email.indexOf("@") === -1 && setValidationMessage("Please Enter A Valid Email Address")}
        email &&
        // lastName &&
        firstName &&
        email.indexOf("@") > -1 &&
        onValidated({
          MERGE0: email,
          MERGE1: firstName,
          // MERGE2: lastName,
          // MERGE3: birthDay,
        });
      }

    const handleEmailChange = (e) => {
        setEmail(e.target.value.toLowerCase())
    }

    const handleFnameChange = (e) => {
        setFirstName(e.target.value)
    }

    // const handleLnameChange = (e) => {
    //     setLastName(e.target.value)
    // }

    // const handleBdayChange = (e) => {
    //     setBirthDay(e.target.value)
    // }

    useEffect(() => {
    if(status === "sending") setValidationMessage("");
  }, [status])
    
    useEffect(() => {
    if(status === "success") clearFields();
  }, [status])

  const clearFields = () => {
    setFirstName('');
    // setLastName('');
    setEmail('');
    submit();
  }

  return (
    <div className={Styles.Form}>
        <form onSubmit={(e) => handleSubmit(e)}>
            <img src={cancel} className={Styles.cancel} alt="" onClick={setPopUp}/>
        <h3>Enter For Your Chance To Win One (1) THE NIGHTINGALES NFT</h3>
        <div className={Styles.validationMessage}>{validationMessage}</div>
        {status === "sending" && (
          <div className={Styles.validationMessage}>
            sending...
          </div>
        )}
        {status === "error" && (
          <div className={Styles.validationMessage}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div className={Styles.validationMessage}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className={Styles.form}>
            <img src={nightingale} alt="" />
            <input 
                label="email"
                type="email"
                value={email}
                placeholder="EMAIL"
                onChange={(e) => handleEmailChange(e)}
            />
            <input 
                label="firstName"
                type="text"
                value={firstName}
                placeholder="NAME"
                onChange={(e) => handleFnameChange(e)}
            />
            {/* <p>Last Name</p>
            <input 
                label="lastName"
                type="text"
                value={lastName}
                placeholder="Smith"
                onChange={(e) => handleLnameChange(e)}
            /> */}
            {/* <p>Birthday</p>
            <input 
                label="birthDay"
                type="date"
                value={birthDay}
                placeholder="John"
                onChange={(e) => handleBdayChange(e)}
            /> */}
            <button type="submit"> CLICK HERE TO ENTER NOW </button>
        </div>
        </form>
    </div>
  )
}

export default LandingPageForm