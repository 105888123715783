import React from "react";

import PIBG from "./../../Images/Project-comp.png";

import Styles from "./Project.module.css";

const ProjectIdeation = () => {
  return (
    <div className={Styles.ProjectIdeation} >
      <div>
        {/* <div className={Styles.PIbg}></div> */}
        <img className={Styles.PIbg} src={PIBG} alt="" />
        <div className={Styles.PItext}>
        <h2>Why Should you adopt a Peace Nightingale today?</h2>
        <p>
        We donate directly and equally to <a href="#Difference"> 17 top-rated NGOs </a> that are helping 
Ukraine. These are specifically chosen by the team for the exemplary work
they have exhibited during these times of crisis. 
          <br />
          <br />
          {/* Here are our 17 NGOs partners who are providing aid to Ukrainians. :-
          <ol>
            <li>Food For Life Global-Americas: Supplies free meals to the hungry. </li>
            <li>Project HOPE: Delivers medical aid and healthcare to the survivors. </li>
            <li>World Central Kitchen: Provides meals and food supply in the wake of war or natural disaster. </li>
            <li>Global Fund for Children: Helping Ukrainians rebuild their lives and confidence.</li>
            <li>Danish Refugee Council: International humanitarian displacement organization to help war refugees find a home.</li>
            <li>Direct Relief: Provide healthcare workers in Ukraine with essential equipment and resources.</li>
            <li>United Way Worldwide: Provides health, education, and financial assistance to Ukrainians. </li>
            <li>SOS Children's Villages: World's biggest children's charity organization to provide food, shelter, education, and health to orphans.</li>
            <li>ActionAid USA: Safeguards the social and ecological rights of the people in Ukraine amidst the ongoing conflict.</li>
            <li>Save the Children®️: Delivering health and education to children in Ukraine. </li>
            <li>Alight: Alight is helping people in Ukraine to rebuild their lives with dignity and self-sufficiency. </li>
            <li>Good360: Helping businesses in Ukraine perform better and with social responsibility.</li>
            <li>Mercy Corps: a team of humanitarians working together on the front lines of crisis and disaster in Ukraine!</li>
            <li>CARE: Providing nutrition to children for a healthy life amidst a crisis. </li>
            <li>International Fund for Animal Welfare (IFAW): Provides animal welfare to innocent animals in Ukraine.</li>
            <li>WONDER Foundation: Empowering women and girls to get the education they need.</li>
            <li>Committee to Protect Journalists: Protects the rights of journalists and freedom fighters in war-struck regions.</li>
          </ol>
          <br /> */}
          Through each purchase from the collection, you can motivate thousands of
crypto investors to help a country which is devastated right now. Helping 
Ukraine receive all the help it requires to ferry this turbulent time.
          <br />
          <br />
          Stand Against War is a transparent collective fuelled with passion to end 
wars and help the people affected the most by it. We have a very simple 
money model and you can check it out below.
          <br />
          <br />
          With every surge in the crypto-market, your “help” will swell and in turn 
NGOs will receive higher value of donation than what you contributed! (We
are mind-boggled too...)
          <br />
          <br />
          </p>
        {/* <img src={image} className={Styles.PIBI} alt="" /> */}
        <img src={PIBG} className={Styles.PIBG} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ProjectIdeation;
