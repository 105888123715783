import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import ilkbitcoin from "./../../Images/ilkBitcoim.png";
import AMBcrypto from "./../../Images/AMBcrypto.png";
import binbits from "./../../Images/binbits.png";
import coingape from "./../../Images/coingape.png";
import cryptonews from "./../../Images/cryptonews.png";
import left from "./../../Images/leftarrow.png";
import right from "./../../Images/rightarrow.png";
import coinrise from "./../../Images/coinrise.png";
import newsbtc from "./../../Images/newsbtc.png";
import bitcoinist from "./../../Images/bitcoinist.png";

import Styles from "./Spotlight.module.css";

const Spotlight = () => {
  const [size, setSize] = useState(window.innerWidth);

  const handleScreenResize = () => {
    setSize(window.innerWidth);
  };

  window.addEventListener("resize", handleScreenResize);

  const blogs = [
    {
      image: AMBcrypto,
      heading:
        "StandAgainstWar is helping war-torn Ukraine with crypto donations...",
      text: "The world is witnessing the largest conventional military attack in Europe since World War II. And, this severe humanitarian crisis is getting worse with each passing day...",
      more: "https://ambcrypto.com/standagainstwar-is-helping-war-torn-ukraine-with-crypto-donations/",
      website: "AMBcrypto",
    },
    {
      image: cryptonews,
      heading:
        "StandAgainstWar: Aiding the Fight in Ukraine Through Blockchain Technology",
      text: "It seems as if most of the world is doing whatever they can to offer support to Ukraine, and it was only a matter of time before a project like StandAgainstWar came into the picture...",
      more: "https://cryptonews.com/news/standagainstwar-aiding-fight-ukraine-through-blockchain-technology.htm",
      website: "CryptoNews",
    },
    {
      image: coingape,
      heading:
        "Stand Against War Partners With 17 NGOs To Make Crypto Donations To Ukraine",
      text: "We are practically on the verge of another World War. Millions of families are fleeing Ukraine, and this number is expected to increase exponentially. It is no mystery that...",
      more: "https://coingape.com/stand-against-war-partners-with-17-ngos-to-make-crypto-donations-to-ukraine/",
      website: "CoinGape",
    },
    {
      image: binbits,
      heading:
        "Helping Ukraine with Crypto: What Makes StandAgainstWar Reliable?",
      text: "Have you pondered how you can make a difference when it comes to the Ukrainian war? Russian soldiers are moving closer to the capital daily...",
      more: "https://binbits.com/helping-ukraine-with-crypto-what-makes-standagainstwar-reliable/",
      website: "BinBits",
    },
    {
      image: ilkbitcoin,
      heading:
        "StandAgainstWar accelerates crypto donations for Russian-occupied Ukraine",
      text: "Losing your home and loved ones is an indescribable pain. However, even as we speak, more than a million Ukrainians are experiencing it, and that number is expected to rise...",
      more: "https://ilkbitcoin.com/standagainstwar-rus-isgalindeki-ukrayna-icin-kripto-bagislarini-hizlandiriyor/",
      website: "ilkBitcoin",
    },
    {
      image: coinrise,
      heading: "Help Ukraine Using Crypto Donations",
      text: "Anyone who is a fan of cryptocurrency will love NFT releases, and it seems as if there is a new collection that has plenty of potential. All of us know about the war in Ukraine...",
      more: "https://thecoinrise.com/help-ukraine-using-crypto-donations/",
      website: "TheCoinRise",
    },
    {
      image: AMBcrypto,
      heading:
        "Stand Against War releases NFT collection to fund NGOs supporting Ukraine",
      text: "Blockchain technology is an amazing way to send crypto and keep track of every transaction using the ledger, but how does this make donating to causes like war-torn Ukraine easier...",
      more: "https://ambcrypto.com/stand-against-war-releases-nft-collection-to-fund-ngos-supporting-ukraine/",
      website: "AMBcrypto",
    },
    {
      image: binbits,
      heading:
        "Helping with the War in Ukraine: Stand Against War Releasing NFTs for...",
      text: "NFTs have always been looked at as something that will never take off, mainly because the public feels like there is no true use for them. If you think that non-fungible tokens...",
      more: "https://binbits.com/helping-with-the-war-in-ukraine-stand-against-war-releasing-nfts-for-charity/",
      website: "BinBits",
    },
    {
      image: ilkbitcoin,
      heading:
        "NFT’ler veya Kripto Bağışları Yoluyla Ukrayna’ya Yardım Etmek Mümkün mü?",
      text: "Ukrayna’yı desteklemek için yapabileceğiniz bir şey olup olmadığını hiç merak ettiniz mi? Şu anki durumunda kabuğuna çekilmiş olsa da bir zamanlar çoğu Rusça konuşan gururlu...",
      more: "https://ilkbitcoin.com/nftler-veya-kripto-bagislari-yoluyla-ukraynaya-yardim-etmek-mumkun-mu/",
      website: "ilkBitcoin",
    },
    {
      image: coingape,
      heading:
        "Crypto Charity: Learn How To Support The War Effort In Ukraine Through...",
      text: "Anybody who is reading this knows about the war that’s happening in Ukraine right now, and how important providing them with support can be at a time like this. The world is...",
      more: "https://coingape.com/crypto-charity-learn-how-to-support-the-war-effort-in-ukraine-through-blockchain-technology/",
      website: "CoinGape",
    },
    {
      image: cryptonews,
      heading: "Stand Against War is Using NFTs to Support Ukraine in the War",
      text: "Charities aren’t all equal, as most of you reading this would know. Some of them are much larger and get ridiculously massive donations that fund their efforts, but also...",
      more: "https://cryptonews.com/news/stand-against-war-using-nfts-support-ukraine-war.htm",
      website: "CryptoNews",
    },
    {
      image: binbits,
      heading:
        "Learning More About the StandAgainstWar Project: How to Help Ukraine Using Cryptocurrency...",
      text: "The war in Ukraine is getting bigger by the day, and with the most recent set of sanctions throwing a wrench in any sort of resolution, there are plenty of people looking for...",
      more: "https://binbits.com/learning-more-about-the-standagainstwar-project-how-to-help-ukraine-using-cryptocurrency/",
      website: "BinBits",
    },
    {
      image: coinrise,
      heading:
        "StandAgainstWar – People from All Over the World Uniting Through NFTs",
      text: "People from all walks of life are growing more interested in NFTs as a whole, and this is a chance for them to gain exposure and also help Ukraine out at the same...",
      more: "https://thecoinrise.com/standagainstwar-people-from-all-over-the-world-uniting-through-nfts/",
      website: "TheCoinRise",
    },
    {
      image: coingape,
      heading:
        "StandAgainstWar Joins Hands With Multiple Organizations To Help Ukraine",
      text: "The war in Ukraine comes with a horrific toll on the people involved – mental, physical, emotional, and financial. People are losing their lives, their homes, and their loved...",
      more: "https://coingape.com/standagainstwar-joins-hands-multiple-organizations-help-ukraine/",
      website: "CoinGape",
    },
    {
      image: AMBcrypto,
      heading: "Stand Against War donating to help war-torn Ukraine",
      text: "Nonprofit organizations like Project HOPE are working directly with the team at Stand Against War to create a crypto-based donation system that will allow people from all over the world to support...",
      more: "https://ambcrypto.com/stand-against-war-donating-to-help-war-torn-ukraine/",
      website: "AMBcrypto",
    },
    {
      image: coinrise,
      heading:
        "Feeling Helpless Over the Ukraine Situation? Help Using Cryptocurrency and NFTs",
      text: "If you’re reading this right now and feel helpless over the situation in Ukraine, you aren’t alone. As a matter of fact, most of the world is coming together and showing support for Ukraine; Russia...",
      more: "https://thecoinrise.com/feeling-helpless-over-the-ukraine-situation-help-using-cryptocurrency-and-nfts/",
      website: "TheCoinRise",
    },
    {
      image: cryptonews,
      heading:
        "Can Cryptocurrency Be the Key to Helping Ukraine? StandAgainstWar Thinks So!",
      text: "If you’re reading this and don’t know about the events that are taking place in Ukraine, it’s highly recommended that you read into it. Not only are they suffering as a country, but it doesn’t look...",
      more: "https://cryptonews.com/news/can-cryptocurrency-key-helping-ukraine-standagainstwar-thinks-so.htm",
      website: "CryptoNews",
    },
    {
      image: ilkbitcoin,
      heading:
        "Peace Nightingale benzersiz NFT koleksiyonuyla Ukrayna’ya destek olabilirsiniz",
      text: "Ukrayna’ya destek olan kuruluşlara bağış yapmak son derece iyi bir şey, ancak önemli olan nokta, bunu doğru şekilde yapmaktır. Hemen hemen her ülke Ukrayna’ya yardım etmek için üzerine...",
      more: "https://ilkbitcoin.com/peace-nightingale-benzersiz-nft-koleksiyonuyla-ukraynaya-destek-olabilirsiniz/",
      website: "ilkBitcoin",
    },
    {
      image: newsbtc,
      heading:
        "StandAgainstWar: Offering A New Medium To Donate Crypto For Ukraine Relief ...",
      text: "The conditions in Ukraine have been deteriorating by the minute, and now is the time to share this load by offering any and every form of help that can create an impact. The Stand Against War...",
      more: "https://www.newsbtc.com/sponsored/standagainstwar-offering-a-new-medium-to-donate-crypto-for-ukraine-relief-through-peacenightingales/",
      website: "NewsBTC",
    },
    {
      image: bitcoinist,
      heading:
        "The Peace Nightingales NFTs: A Collections Curated To Help Aid Ukraine ...",
      text: "Charities aren’t all equal, as most of you reading this would know. Some of them are much larger and get ridiculously massive donations that fund their efforts, but also the people that work...",
      more: "https://bitcoinist.com/the-peace-nightingales-nfts-a-collections-curated-to-help-aid-ukraine-through-crypto-donations/",
      website: "Bitcoinist",
    },
  ];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      >
        <img src={right} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <img src={left} alt="" />
      </div>
    );
  }

  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: size / 350,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className={Styles.spotlight}>
      <div>
        <h1>We Are Under The Spotlight!</h1>
        <section>
          <Slider {...settings}>
            {blogs
              .reverse()
              .map(({ image, heading, text, more, website }, k) => (
                <div
                  key={k}
                  onClick={() => window.open(`${more}`, "_blank")}
                  className={Styles.blogItem}
                >
                  <img src={image} alt="" />
                  <div className={Styles.text}>
                    <h3>{heading}</h3>
                    <p>{text}</p>
                    <h4>{website}</h4>
                  </div>
                </div>
              ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Spotlight;
