import React, { useState } from "react";
import { Link } from "react-router-dom";
import Styles from "./Header.module.css";
import Logo from "../../assets/image/Logo.png";
import menu from "../../Images/hamburgerMenu.png";

const Header = () => {

  const [menuModal, setMenuModal] = useState(false);
  
  return (
    <nav className={Styles.nav}>
      <marquee className={Styles.marquee} behaviour="slide" direction="left">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(
          (item) => (
            <span key={item}>Help Ukraine!!!</span>
          )
        )}
      </marquee>
      <div className={Styles.navContainer}>
        <div className={Styles.logo} onClick={() => (window.location.href = "/")}>
          <img src={Logo} alt="" />
          <h2>
            Stand<span>Against</span>War
          </h2>
        </div>
          <div className={Styles.socialIcons}>
            <a href="https://twitter.com/againstwar_nfts" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://discord.gg/rRk28c49wd" target="_blank">
              <i className="fab fa-discord" />
            </a>
            <a href="https://www.instagram.com/standagainst.war/" target="_blank">
              <i className="fab fa-instagram" />
            </a>
          </div>
        <div className={Styles.navLinks}>
          <ol>
            <li> <Link to="/">Home</Link></li>
            <li>
              <a href="/#About">About</a>
            </li>
            {/*<li>Hall Of fame</li>
            <li>Blog</li> */}
            <li>
              <button onClick={() => (window.open ("https://discord.gg/rRk28c49wd", "_blank"))}>
                Join Community
              </button>
            </li>
          </ol>
        </div>
        <div className={Styles.menu}>
          <img src={menu} onClick={() => setMenuModal(!menuModal)} alt="" />
        </div>
        {menuModal && <div className={Styles.menuModal}>
        <div className={Styles.mobileLinks}>
          <ol onClick={() => setMenuModal(false)}>
            <li> <Link to="/">Home</Link></li>
            <li>
              <a href="/#About">About</a>
            </li>
            {/* <li>Hall Of fame</li>
            <li>Blog</li> */}
            <li>
            <button onClick={() => (window.open ("https://discord.gg/rRk28c49wd", "_blank"))}>
               Join Community
              </button>
            </li>
          </ol>
        </div>
        </div>}
      </div>
    </nav>
  );
};

export default Header;
