import React from 'react';

import Styles from "./Authenticated.module.css";

const Authenticated = ({logo, text, link}) => {
  return (
    <div className={Styles.main}>
        <p>{text}</p>
        <img src={logo} alt="" onClick={() => {link && window.open(link, "_blank")}}/>
    </div>
  )
}

export default Authenticated 