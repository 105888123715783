import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Images
import loader from "../../Images/loader.png";
import bird from "../../Images/bird.png";
import close from "../../Images/cancel.png";
import congratimg from "../../Images/congrats.png";
import CircleImg from "../../assets/image/whoAreWe.png";

// chart

import Styles from "./Mint.module.css";

const Mint = () => {
  const [token, setToken] = useState(7);
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);

  const [mintModal, setMintModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const tokenChangeHandler = (e) => {
    setToken(e.target.value);
  };

  const submitTransaction = () => {
    setLoading(true);

    // calls Approve function in 4 seconds
    setTimeout(() => {
      Approve();
    }, 4000);
  };

  // Approve FUnction
  const Approve = () => {
    setTransactionStatus(true);

    // Stop loading on transaction status update
    setLoading(false);
  };

  // Deny function (Unused)
  const Deny = () => {
    setTransactionStatus(false);

    // Stop loading on transaction status update
    setLoading(false);
  };

  //  Called when user closes the modal after transaction is either succefull or not to reset progress
  const endTransaction = () => {
    setTransactionStatus(null);
    setStatusModal(false);
  };

  // Closes mint modal and opens status modal on any status update
  useEffect(() => {
    if (transactionStatus !== null) {
      setMintModal(false);
      setStatusModal(true);
    }
  }, [transactionStatus]);

  return (
    <div className={Styles.Mint}>
      <Header />

      {/* Mint Modal */}
      {mintModal && (
        <section className={Styles.mintModal}>
          {!loading && (
            <div className={Styles.transact}>
              <img
                src={close}
                className={Styles.closeModal}
                onClick={() => setMintModal(false)}
                alt=""
              />
              <h2>Approve The Transaction</h2>
              <p>Minting Another TOKEN?! You Must Be A Super Supporter</p>
              <p>
                Quantity
                <input
                  type="number"
                  value={token}
                  onChange={(e) => tokenChangeHandler(e)}
                />
              </p>
              <button onClick={submitTransaction}>Submit Transaction</button>
            </div>
          )}

          {loading && (
            <div className={Styles.loading}>
              <img src={loader} alt="" />
              <h2>Transaction In Progress</h2>
              <p>
                This can take a few minutes depending on gas. Don’t leave this
                page... you can do whatever you want, this uses Ethereum!
              </p>
              <button>View Transaction Status</button>
            </div>
          )}
        </section>
      )}

      {/* Status Modal */}
      {statusModal && (
        <section className={Styles.statusModal}>
          {transactionStatus ? (
            <div className={Styles.success}>
              <img
                src={close}
                className={Styles.closeModal}
                onClick={() => endTransaction()}
                alt=""
              />
              <img src={congratimg} className={Styles.congratimg} alt="" />
              <h2>Congratulations!</h2>
              <img src={bird} alt="" />
              <h2>Meet Your New Token</h2>
              <p>
                Bravo! A Unique Nightingale has left for the journey to reach
                you in record time! The nightingales believe in a collective
                approach, and yours is no different! Stay putt, your nightingale
                will reveal itself soon!
              </p>
              <button onClick={() => (window.location.href = "./")}>
                View My Unique Nightingale Bird
              </button>
            </div>
          ) : (
            <div className={Styles.fail}>
              <img
                src={close}
                className={Styles.closeModal}
                onClick={() => endTransaction()}
                alt=""
              />
              <h2>Something Went Wrong</h2>
              <button onClick={() => (window.location.href = "./")}>
                Go Back To Homepage
              </button>
            </div>
          )}
        </section>
      )}

      {/* START HERE */}

      <section className={Styles.Main}>
        <div>
          <span />
          <div>
            <h1>0/10000</h1>
            <p className={Styles.address}>0x827acb09a2dc20e39c9a......</p>

            <h3 className={Styles.gasText}>1 GCP costs 100 Matic</h3>
            <p>Excluding gas fees</p>
            <br />
            <p>Connect to the Polygon network</p>
            <button
              className={Styles.connectButton}
              onClick={() => setMintModal(true)}
            >
              Connect
            </button>
          </div>
          <span />
        </div>

        <div>
          <div>
            {/* <p>
              Please make sure you are connected to the right network (Ethereum
              Mainnet) and the correct address. Please note: Once you make the
              purchase, you cannot undo this action.
            </p>
            <p>
              We have set the gas limit to 323232323 for the contract to
              successfully mint your NFT. We recommend that you don't lower the
              gas limit.
            </p> */}
            <p>
              Our Peace Nightingales are now fed, trained, vetted and ready to
              be adopted! Adoption allows investors membership into a fun social
              community with the aim to stand against war, duh! If you are here,
              you maybe answering an inner calling to restore peace, harmony and
              brotherhood for humanity 2.0. Let’s unite, mint and mend one
              crisis at a time!
            </p>
            <p>
              ** This is a collection of 10,000 unique NFTs and each peace
              nightingale is one of a kind.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Mint;
