import React from "react";

import alight from "./../../Images/alight-logo.png";
import fel from "./../../Images/fel.png";
import actionaid from "./../../Images/actionaid.png";
import danish from "./../../Images/danish.png";
import directrelief from "./../../Images/directrelief.png";
import ifaw from "./../../Images/ifaw.png";
import mercy from "./../../Images/mercy.png";
import power from "./../../Images/power.png";
import wonder from "./../../Images/wonder.png";
import cpj from "./../../Images/cpj.png";
import united from "./../../Images/united.png";
import banner from "./../../Images/banner.svg";
import global from "./../../Images/global.png";
import hands from "./../../Images/hands.png";
import happy from "./../../Images/happy.png";
import wck from "./../../Images/wck.png";
import hope from "./../../Images/hope.png";
import play from "./../../Images/play.png";

import Styles from "./Difference.module.css";

const Difference = () => {
  const list = [
    {
      image: alight,
      text: "Alight is helping people in Ukraine to rebuild their lives with dignity and self sufficiency after facing the war oriented turbulence. ",
    },
    {
      image: fel,
      text: "FFL global fights the ever persistent issue of hunger through supplying free meals using their channel of affiliates in Ukraine! Sponsor a meal!",
    },
    {
      image: actionaid,
      text: "Action Aid works towards safeguarding social and ecological rights of the people in Ukraine amidst the ongoing crisis.",
    },
    {
      image: danish,
      text: "Danish Refugee Council is a leading, international humanitarian displacement organisation which is supporting the ones who lost their home in Ukraine!",
    },
    {
      image: global,
      text: "Helping the young minds of Ukraine rebuild their confidence by using the same money that you donate by buying a peace nightingale.",
    },
    {
      image: directrelief,
      text: "With each NFT that you mint, Direct Relief is providing the healthcare workers in Ukraine with the best possible equipment and resources",
    },
    {
      image: happy,
      text: "Healthy, Educated and Safe Children in Ukraine. That's what it means when you get a peace nightingale home.",
    },
    {
      image: hope,
      text: "Project Hope, the globally renowned organization is utilizing your NFT driven donations for restoring the lost hope in Ukraine!",
    },
    {
      image: ifaw,
      text: "As the name suggest, IFAW works towards ensuring equal animal welfare to support the mute ones in Ukraine!",
    },
    {
      image: mercy,
      text: "Mercy Corps is a global team of humanitarians working together on the front lines of crisis and disaster in Ukraine!",
    },
    {
      image: power,
      text: "While you buy NFTs and boost the culture of digital art, Good 360 is incessantly helping the businesses in Ukraine with their social responsibility.",
    },
    {
      image: wonder,
      text: "Wonder is on the mission to empower women and girls to get the education they need to exit poverty for good.",
    },
    {
      image: hands,
      text: "Care ensures nutrition to children that will enable them to lead a healthy life once the crisis is over in Ukraine!",
    },
    {
      image: cpj,
      text: "CPJ promotes press freedom and is currently protecting the rights of journalists in war struck Ukraine!",
    },
  ];

  return (
    <div className={Styles.Difference} id="Difference" >
      <h1>How You Are Making A Difference</h1>
      <section className={Styles.List}>
        {list.map(({ image, text }, k) => (
          <div key={k}>
            <img src={image} alt="" />
            <span>{text}</span>
          </div>
        ))}
      </section>
      <div className={Styles.Banner}>
        <img src={banner} alt="" />
      </div>
    </div>
  );
};

export default Difference;
