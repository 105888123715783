import React, { useState, useEffect } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Form from "./LandingPageForm";

import Styles from "./LandingPagePopUp.module.css";

const LandingPagePopUp = props => {

    const [popUp, setPopUp] = useState(false);
    const [show, setShow] = useState(true);
    const pop_status = localStorage.getItem('pop_status');

    useEffect(()=>{
        setTimeout(function(){
            if(!pop_status){
                setPopUp(true);
              localStorage.setItem('pop_status', 1);
            }
          }, 5000);

          if (pop_status == 2){
            setShow(false);
        }
      },[])

      const update = () => {
        setPopUp(true);
        localStorage.setItem('pop_status', 1);
      }

      const handleSubmit = () =>{
          setTimeout(function(){
            setPopUp(false);
            localStorage.setItem('pop_status', 2);
            setShow(false);
          }, 2500)
      }

  
  return (
        <div className={`${!popUp && Styles.closed} ${Styles.popUp} ${!show && Styles.none}`} onClick={() => {!popUp && update()}} id="GetWhitelisted">
        <MailchimpSubscribe 
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
                <Form 
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                    setPopUp={() => setPopUp(false)}
                    submit={() => handleSubmit()}
                />
            )}
            />
    </div>
  )
}

export default LandingPagePopUp
