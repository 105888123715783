import React from "react";
import { Link } from "react-router-dom";
import Styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div className={Styles.container}>
        <div className={Styles.navLinks}>
          <ul>
            {/* <li>
              <Link to="./">Connect</Link>
            </li> */}
            <li>
              <a href="mailto:help@standagainstwar.com">Contact</a>
            </li>
            {/* <li>
              <Link to="./">Opensea</Link>
            </li> */}
          </ul>
        </div>
        <div className={Styles.socialIcons}>
          <a href="https://twitter.com/againstwar_nfts" target="_blank">
            <i className="fab fa-twitter" />
          </a>
          <a href="https://discord.gg/rRk28c49wd" target="_blank">
            <i className="fab fa-discord" />
          </a>
          <a href="https://www.facebook.com/Stand-Against-War-100332872629564" target="_blank">
            <i className="fab fa-facebook" />
          </a>
          <a href="https://www.instagram.com/standagainst.war/" target="_blank">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
