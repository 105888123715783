import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Form from "./Form";

import Styles from "./Mailchimp.module.css";

const Mailchimp = props => {
  
  return (
    <div className={Styles.Email} id="GetWhitelisted">
        <MailchimpSubscribe 
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
                <Form 
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
            />
    </div>
  )
}

export default Mailchimp