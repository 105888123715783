import React from "react";
import Styles from "./Pride.module.css";

const Pride = () => {
  return (
    <section>
      <div className={Styles.container}>
        <div />
        <div>
          <h4 className={Styles.subTitle}>Wear with pride!</h4>
          <h2 className={Styles.title}>
            Wear your token with pride and affect change!
          </h2>

          <div>
            <p>
              When you display the Peace nightingale, that you just adopted, as
              your profile picture, you will inspire others to affect change and
              provide substantial help not just sympathy.
            </p>
            <p>
              A token that represents your pragmatism, solidarity and capacity
              for compassion.
            </p>
            <p>
              A conversation starter that will ground your beliefs in solid
              actions (not just gab).
            </p>
            <p>
              Spread the word and help Ukraine find all the help it requires.
            </p>
          </div>
        </div>
        <div />
      </div>
    </section>
  );
};

export default Pride;
