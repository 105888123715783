import React from 'react';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import PNF from "../../Images/PNFBG.png";

import Styles from "./PageNotFound.module.css";

const PageNotFound = () => {
  return (
    <div className={Styles.PageNotFound}>
        <Header />
        <section className={Styles.pnfmain}>
            <img src={PNF} alt="" />
            <div className={Styles.Text}>
                <h2>404 - Page Not Found</h2>
                <button onClick={() => window.location.href="./"}>Back To Homepage</button>
            </div>
        </section>
        <Footer />
    </div>
  )
}

export default PageNotFound