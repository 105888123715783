import React, { useState } from "react";
import { Link } from "react-router-dom";

import HeroImg from "./../../Images/heroImg.png";

import Styles from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={Styles.Hero}>
      <div className={Styles.Main} >
        <div className={Styles.Text}>
          <h1>Adopt a Peace Nightingale: Help Ukrainians deal with crisis!</h1>
          <p>
          We are a social collective with the aim to build a community of peace-lovers 
          eager to help victims of war crisis. Through the first collection, we are driving 
          donations to help Ukrainians through 17 top-rated NGOs from across the globe.
          </p>
          <button onClick={() => (window.location.href = "/peacenightingales/#GetWhitelisted")}>
            <h2>0/10k Minted</h2> <p>Get Whitelisted</p>
          </button>
        </div>
        <div className={Styles.Image}>
          <img src={HeroImg} alt="" />
          <img src={HeroImg} alt="" />
          <img src={HeroImg} alt="" />
          <img src={HeroImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
