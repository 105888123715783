import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import LandingPagePopUp from "./components/Mailchimp/LandingPagePopUp";

import Home from "./pages/Home/Home";
import Landing from "./pages/Landing/Landing";
import Mint from "./pages/Mint/Mint";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
  return (
    <div className="App">
       <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/form" element={<LandingPagePopUp />} />
            <Route path="/home" element={<Landing />} />
             <Route path="/peacenightingales" element={<Home />} />
            <Route path="/peacenightingales/mint" element={<Mint />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
