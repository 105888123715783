import React from "react";

import Styles from "./Blogs.module.css";

const Blogs = () => {
  return (
    <div className={Styles.Blogs}>
      <button onClick={() => (window.location.href = "/peacenightingales/#GetWhitelisted")}>
        <h2>0/10k Minted</h2> <p>Get Whitelisted</p>
      </button>
    </div>
  );
};

export default Blogs;
