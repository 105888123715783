import React from 'react';

import Styles from "./FAQ.module.css";

const FAQ = () => {
    const faq = [
        {
            question: 'What is Stand Against War?',
            answer: 'Stand Against War is a social collective that brings about a digital art revolution to support social causes. In our first project, we have created a collection of 10,000 unique NFTs called the Peace Nightingales that will help the people in Ukraine!',
        },
        {
            question: 'Will Stand Against War launch more collections?',
            answer: 'Stand Against War is an ongoing initiative that will tackle all the social causes that have infecting the roots of the world. The team will launch multiple collections to support each initiative, and will also allow the community members to ideate on forthcoming projects.',
        },
        {
            question: 'How can I work for Stand Against War?',
            answer: 'Being a social collective, we are always looking out to get more and more volunteers on board. If you think that you can help us in any capacity ranging from artwork creation to digital marketing and promotion, you can reach out to us at help@standagainstwar.com',
        },
        {
            question: 'How are the donations getting transferred to the NGOs?',
            answer: 'The functionality of the entire donation segment runs on the basis of a smart contract that has been audited multiple times to evade errors. 90% of the total sales proceeds plus 90% of the totally royalties earned will be directly transferred to our partner NGOs. The sample receipts and the proof of collaboration is up on our social media channels!',
        },
        {
            question: 'How are these donations helping Ukraine directly?',
            answer: 'The total donation amount is equally divided amongst the seventeen partner NGOs that have presented their proof of work to us in Ukraine. For eg: CPJ is protecting the rights of journalists whereas Wonder Foundation is working to empower girls and women to read and write during such tough times.',
        },
    ]

  return (
    <div className={Styles.FAQ} >
        <section className={Styles.main}>
            <h1>Frequently Asked Questions</h1>
            <div className={Styles.FAQtab}>
                {faq.map(({question, answer}, k) => (
                <details key = {k} >
                    <summary>{question}</summary>
                    <p>{answer}</p>
                </details>
                ))}
            </div>
        </section>
    </div> 
  )
}

export default FAQ