import React from "react";
import Styles from "./WhoAreWe.module.css";

const WhoAreWe = () => {
  return (
    <section id="About" >
      <div className={Styles.container}>
        <div className={Styles.wordsDiv}>
          <h4 className={Styles.subTitle}>Who are "The Peace Nightingales"?</h4>
          <h2 className={Styles.title}>
            The Peace <br /> Nightingale's Mission
          </h2>
          <p>
          After flying around the globe for 80 days or more, and hearing the perpetual 
prayers for peace and cries of plea, the peace nightingales have sworn to 
rehabilitate and restore Ukraine to its former glory. Every time you adopt a 
Peace Nightingale it will fly directly to Ukraine to help masses repair, relocate 
and rebuild.
          </p>
          <p>
          <span> The Peace Nightingales </span> is an NFT collection comprising of 10,000 unique tokens 
that are waiting to be minted. When you purchase any of the Nightingales from 
the collection, 90% of the proceeds and royalties will be donated to 17 
handpicked top-rated international NGOs working to help Ukraine and Ukrainians
through this crisis. What’s cooler is that, you can wear your badge of honour, 
show-off your noble deed and inspire others to real action. Don’t just sit and feel 
sorry for Ukraine when you can donate and actually do something about it!
          </p>
        </div>
        <div className={Styles.imgDiv} />
      </div>
      <marquee className={Styles.marquee} behaviour="slide" direction="left">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(
          (item) => (
            <span key={item}>Help Ukraine!!!</span>
          )
        )}
      </marquee>
    </section>
  );
};

export default WhoAreWe;
