import React,{ useEffect, useState } from "react";

import Blogs from "../../components/Blogs/Blogs";
// import Spotlight from '../../components/Spotlight/Spotlight';
import Difference from "../../components/Difference/Difference";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Mailchimp from "../../components/Mailchimp/Mailchimp";
import Pride from "../../components/Pride/Pride";
import ProjectIdeation from "../../components/ProjectIdeation/Project";
import WhoAreWe from "../../components/WhoAreWe/WhoAreWe";
import rarity from "./../../Images/rarity.png";
import certik from "./../../Images/certik.png";

import Styles from "./Home.module.css";

import { Helmet } from "react-helmet";
import LandingPagePopUp from "../../components/Mailchimp/LandingPagePopUp";
import AOS from "aos";
import "aos/dist/aos.css";
import Team from "../../components/Team/Team";
import Authenticated from "../../components/Authenticated/Authenticated";
import Popup from "../../components/MintPopUp/Popup";

const Home = () => {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

    const [active, setActive] = useState(false);
    const close = () => {
      setActive(false);
    }

  return (
    <div className={Styles.Home}>
      <Helmet htmlAttributes>
        <title>Peace Nightingale NFT | Stand Against War NFT | Crypto Donation NFT | Russia Ukraine Victim relief fund</title>
        <meta name="og:title" content="Peace Nightingale NFT | Stand Against War NFT "/>
        <meta name="description" content="Stand Against War is an initiative to support war struck nations during times of dire need! Our collection Peace Nightingales is an effort towards donating the proceeds of 10,000 unique NFTs to help Ukraine amidst the ongoing crisis!"/>
        <meta name="og:description" content="Stand Against War is an initiative to support war struck nations during times of dire need! Our collection Peace Nightingales is an effort towards donating the proceeds of 10,000 unique NFTs to help Ukraine amidst the ongoing crisis!"/>
        <meta name="og:url" content="https://standagainstwar.com/"/>
        <meta name="og:site_name" content="https://standagainstwar.com/"/>
        <meta name="og:type" content="website"/>
      </Helmet>
      <Header />
      <Hero />
      <Authenticated text='We are authenticated by' logo={certik} />
      {active && <Popup close={close}/>}
      <Mailchimp />
      <WhoAreWe />
      <LandingPagePopUp />
      <ProjectIdeation />
      <Pride />
      <Difference />
      {/* <Spotlight /> */}
      <Blogs />
      <Team />
      <FAQ />
      <Authenticated text='Check rarity on' logo={rarity} link={"https://raritysniper.com"}/>
      <Footer />
    </div>
  );
};

export default Home;
